<template>
	<div class="diy_list page_commodity_management" id="commodity_management_list">
		<div class="warp">
			<div class="container">
				<div class="row">
					<div class="col">
						<span class="title">商品管理列表</span>
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 搜索栏 -->
						<div class="view">
							<b-form-input size="sm" class="mr-sm-2" placeholder="商品名称搜索" v-model="query['trade_name']" />
							<b-form-input size="sm" class="mr-sm-2" placeholder="类别搜索" v-model="query['category']" />
							<b-button size="sm" @click="search()" >
								<b-icon icon="search"/>
							</b-button>
						</div>
						<!-- /搜索栏 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 筛选 -->
						<div class="view">
							<b-dropdown :text="title" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o, i) in list_category" :key="i" @click="filter_set(o['o.name'],'category')" >
							        {{ o['o.name'] }}
							    </b-dropdown-item>
							</b-dropdown>
						</div>
						<!-- /筛选 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 排序 -->
						<div class="view">
							<b-dropdown text="排序" variant="outline-dark" left>
							    <b-dropdown-item v-for="(o, i) in list_sort" :key="i" @click="set_sort(o)" >
							        {{ o.name }}
							    </b-dropdown-item>
							</b-dropdown>
						</div>
						<!--/排序 -->
					</div>
				</div>
				<div class="row">
					<div class="col">
						<!-- 列表 -->
						<list_commodity_management :list="list" />
						<!-- /列表 -->
					</div>
				</div>
				<div class="row">
					<div class="col overflow-auto flex_cc">
						<!-- 分页器 -->
						<diy_pager v-model="query['page']" :size="query['size']" :count="count" v-on:toPage="toPage" v-on:toSize="toSize" ></diy_pager>
						<!-- /分页器 -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import list_commodity_management from "@/components/diy/list_commodity_management.vue";
	import diy_pager from "@/components/diy/diy_pager";
	import mixin from "@/mixins/page.js";

	export default {
		mixins: [mixin],
		components: {
			diy_pager,
			list_commodity_management
		},
		data() {
			return {
				url_get_list: "~/api/commodity_management/get_list?like=0",

				// 查询条件
				query: {
					keyword: "",
					page: 1,
					size: 10,
					"trade_name": "", // 商品名称
					"category": "", // 类别
				},

				// 排序内容
				list_sort: [{
						name: "创建时间从高到低",
						value: "`create_time` desc",
					},
					{
						name: "创建时间从低到高",
						value: "`create_time` asc",
					},
					{
						name: "更新时间从高到低",
						value: "`update_time` desc",
					},
					{
						name: "更新时间从低到高",
						value: "`update_time` asc",
					},
					{
						name: "商品名称正序",
						value: "`trade_name` asc",
					},
					{
						name: "商品名称倒序",
						value: "`trade_name` desc",
					},
					{
						name: "类别正序",
						value: "`category` asc",
					},
					{
						name: "类别倒序",
						value: "`category` desc",
					},
				],
				// 类别列表
				"list_category": [],
			}
		},
		methods: {
			/**
			 * 筛选选择
			 */
			filter_set(o,key) {
			    if (o == "全部") {
			        this.query[key] = "";
			    } else {
			        this.query[key] = o;
			    }
			    this.search();
			},

			/**
			 * 排序
			 */
			set_sort(o) {
			    this.query.orderby = o.value;
			    this.search();
			},
			/**
			 * 获取类别列表
			 */
			async get_list_category() {
				var json = await this.$get("~/api/classification_information/get_list?");
				if (json.result) {
					this.list_category = json.result.list;
				} else if (json.error) {
					console.log(json.error);
				}
			},

			/**
			 * 筛选
			 */
			filter_category(o) {
				if (o == "全部") {
					this.query["category"] = "";
				} else {
					this.query["category"] = o;
				}
				this.search();
			},

			/**
			 * 重置
			 */
			reset() {
				this.query.trade_name = ""
				this.query.category = ""
				this.search();
			},

			// 返回条数
			toSize(i){
				this.query.size = i;
				this.first();
			},

			// 返回页数
			toPage(i){
				this.query.page = i;
				this.first();
			}
		},
		computed: {
		},
		created() {
			/**
			 * 获取类别列表
			 */
			this.get_list_category();
		}
	}
</script>

<style>
</style>
